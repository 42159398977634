@media (max-width: 768px) {
  
    .qaqc-answer1 {
        margin-left: 0 !important;
    }

    .office-report-modal-content {
        width:90% !important;
        margin-top: 10% !important;
    }
  }

.modal-for-report-office{
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    z-index:1000;
    background-color: #89818173;
    text-align: center;
    padding-top: 15%;
}

.office-report-modal-content {
    width: 500px;
    margin: auto;
    padding:50px;
    background-color: white;
    border-radius: 10px;
}

/* #button-download-as-xls {
    border: none ;
    background: none;
    color: white;
} */