h1 {
    font-size: 40px;
    font-weight: 600;
}

h2 {
    font-size: 30px;
    font-weight: 500 !important;
}

.text-dec {
    text-decoration: underline;
}