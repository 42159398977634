
.modal-survey, .modal-survey .modal-body, .quality-control-module .modal-dialog {
  width:100%;
  margin:0 auto;
  max-height:100%;
  max-width:100%;
  height:100vh;
  background-color:white;
    overflow-y:scroll;
}


.signature .pre-sign {
    background-image:url(../images/signhere.png);
    background-size:30%;
    background-position:center;
    background-repeat:no-repeat
}
.modal-body {
    z-index:1;
    max-width: 100vw;
}

.question-content {
       padding-bottom: 3em;
}
.fileUploader .uploadIcon {
   display:none;
}

.fileupload div:first-of-type .fileContainer .uploadIcon {
display: block !important;
}
.signature canvas {
    height:100px; 
}
.copy-btn img {
    max-width:18px;
    float:left;
}

.survey-complete {
    vertical-align: middle;
}
.progress {
    height:20px !important;
    margin-bottom:1em;
}

.total-points {
    background-color: #FFF8ED;
    border: solid 1px #FFD486;
}
.progress-bar {
    padding-left:10px;
    padding-right:10px;
}
.signature canvas {
   border:solid 1px #C0F0E9;
   background-color: #eafdfa9b;
}
.survey-content {
    width: 100%;
    max-width: 750px;
    margin: 0 auto;
    overflow: visible;
    display: flex;
    -webkit-align-self: center;
    align-self: center;
    justify-self: center;
    align-content: center;
    justify-content: center;
    align-items: center;
    justify-items: center;
    height: -webkit-fill-available;
}
.modal-survey {
   padding: 0 !important;
   margin:0 !important;
   position:absolute;
   top:0;
}
#qaqc {
    width:100%;
    height:100%;
        display:flex;flex-direction: column;

}
.survey-content {
    display:table-cell;
    width:100%;
    vertical-align: middle;
}
.modal-survey .modal-content {
    height: 100vh;
    max-width: 100%;
    position: fixed;
    top: 0;
    border: none !important;
    padding: 100px 0px;

}
.survey {
    max-width: 750px; margin:0 auto;

}
.survey .form-group {
    margin-top:0em;
    margin-bottom:0em;
}
.survey .form-group label {
   font-weight:bold;
}
.survey .form-control {
    text-align:center;
border-radius: 2em;
  /*  background-color:#f1f1f1 !important;*/
    margin-bottom: 1rem;
}
.fileContainer {
    padding: 0 !important;
    box-shadow: none !important;
    margin: 0 !important;
}
.fileContainer p {
    display:none !important;
}

.fileContainer .uploadIcon {
    width: 40px;
    height: 50px;
    opacity: 1
}
.chooseFileButton {
        background: none !important;
        color: #999!important;
        font-size: 10px !important;
        border: solid 2px !important;
        padding: 8px 20px !important;
        width: 90%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 300px;
}
.survey textarea.form-control {
    border-radius:0.6em;
    margin-top:2em;
}
.submission-data {
    padding:1em;border: solid 1px #ccc;
      border-radius: 0.6em;
  background-color:#f1f1f1;
  }
  .submission-data label {
      font-size:10px;
      font-weight:bold;
  }
.survey h2 { font-weight:100;}
label.section {
    padding:0em 0em;

}
label.section span {color:#808080; font-weight:bold;}
.questions {
    border: solid 1px #ccc;
    border-radius: 1rem;
    padding: 3em;
   
}
.answers {
    width: 100% !important;
    margin: 0 auto !important
}
.fileContainer .deleteImage {
    position: absolute;
    top: -9px;
    font-family: "Flaticon" !important;
    content: "\f135";
    right: -9px;
    color: #fff;
    background: #e7283b !important;
    font-family:"poppins" !important;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    font-size: 9px !important;
    font-weight: bold;
    line-height: 20px !important;
    text-shadow:#000000 0px 0px 1px;
    width: 20px !important;
    height: 20px !important;
}
.fileContainer .uploadPictureContainer {
    box-shadow:none !important;
}
.question {
    font-size: 1.25rem;
    line-height: 2em;
    text-align: left;
    margin-bottom: 2rem;
    padding-top: 2rem;


}
.multiple-choice {
    margin-bottom: 0 !important;
    display:inline;
    margin-left:0.6rem;
}

.multiple-choice .btn {
    text-transform: capitalize !important;
    margin-bottom:0.7rem !important;
}
.text-muted {color:#888888;
}
.question span.question-no {
   
    color: #333; /* #e7283b;*/
    border-radius: 3em;
    border: solid 1px #333; /* #e7283b;*/
   width:fit-content;
   height:3em;
   min-width:3em;
    padding-top: 3px;
    display: block;
    margin: 0 auto;
    margin-bottom: 1.6em;
    line-height: 2.6em;

    text-align: center;
  display:none;
}
.modal-survey .close {
    color:white;
}
.quality-control-module .modal-header {
    z-index:100;
    border-radius:0;
    background-color: black;
      position: absolute;
  top: 0;
  left:0;
    width:100%
}
.quality-control-module .modal-header label {
    font-weight: bold;
    color: white;
    margin:0;
}

.modal.show .modal-dialog {
    position:fixed;
}
.quality-control-module .modal-footer {
    background-color:#e2e2e2;
         position: fixed;
    bottom: 0;
    left:0;
    width:100%;
    z-index:100;
}
.selected .rating {
    border-color:  #6E9A00 !important;

  background-color:  #6E9A00 !important;
  color:white !important;

}
.rating.answer-btn {
    font-size:1rem !important;
}
.comments {
    margin-top:2em;
}


@keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

@-webkit-keyframes fadeInUp {
    from {
        transform: translate3d(0,40px,0)
    }

    to {
        transform: translate3d(0,0,0);
        opacity: 1
    }
}

.animated {
    animation-duration: 1s;
    animation-fill-mode: both;
    -webkit-animation-duration: 1s;
    -webkit-animation-fill-mode: both
}

.animatedFadeInUp {
    opacity: 0
}

.fadeInUp {
    opacity: 0;
    animation-name: fadeInUp;
    -webkit-animation-name: fadeInUp;
}


.answers .group {
   
   /* border: solid 1px #da4d4f09;
    background-color: #da4d4f09;
    padding: 1.6em;*/
    border-radius: 1em;
    text-transform:uppercase;
display: block;
width:auto;
margin-left:2%;
margin-right:2%;

}

.answers div:not(.multiple-choice) button:first-of-type {
    margin-bottom:1.25rem !important;

}
.answers button {
    text-transform: uppercase;
}
.answers .group span {
    display:block;
    margin-bottom:1em;
    text-transform:uppercase
}
.question-container h5 {
    margin-bottom:2em;
}
.question-container {
    padding-right:0em;
  
    width:100%;
}
.question-container.disabled {
    position:relative;
    display:table;
    width:100%;
    height:300px;
        border-radius:1rem;
        background-color: rgb(238, 238, 238);
      opacity:0.6;
   border:#ccc;
}
.question-container.disabled .questions, .question-container.disabled h5 {
display:none;padding:0;
}
.question-container .overlay {
    display:none;
}
.question-container.disabled .overlay {
    display:table-cell !important;
    text-align:center;
    width:100%;
    font-size:24px;

    vertical-align: middle;
}
.question-container.disabled .questions {
border:0;
opacity:0.5;
}