.upload-image-operator {
    border:#a8acb4 dashed 2px !important;
    border-radius: 10px;
    padding:10% 0;
    background-color: #f8f9fe;
  }

.image-item__btn-wrapper {
    display: flex;
    justify-content: space-between;
}
